/* eslint-disable */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import queryString from 'query-string';
import { navigate } from 'gatsby';

import {
  BOOKING_DEPOSIT,
  NEW_ADVENTURE_DEPOSIT,
  NEW_ADVENTURE_TOUR_SLUGS,
  CURRENCY,
  PAGES,
} from 'config';
import { BookingLayout } from 'containers';
import { BookingHeader } from 'layouts';
import { mapStateToProps } from 'utilities';
import { CREATE_PAYMENT } from 'actions/payment';
import { FormInput, PaymentCard, withFormGroup, FormSelect } from 'components';

import Countries from 'config/flywire-countries';

const Input = withFormGroup(FormInput);
const Select = withFormGroup(FormSelect);

class BookingsPaymentsPage extends PureComponent {
  constructor(props) {
    super(props);
    const { booking } = this.props;
    const { currentBooking, travellers } = booking;
    this.handlePayment = this.handlePayment.bind(this);
    this.handlePartialSubmit = this.handlePartialSubmit.bind(this);
    if (currentBooking) {
      this.state = {
        firstName: travellers[0].first_name,
        lastName: travellers[0].last_name,
        contactEmail: currentBooking.contact_email,
        phoneNumber: currentBooking.phone,
        address: '',
        city: '',
        country: '',
      }
    } else {
      this.state = {
        firstName: '',
        lastName: '',
        contactEmail: '',
        phoneNumber: '',
        address: '',
        city: '',
        country: '',
      }
    }
    this.onPaymentCountryChanged = this.onPaymentCountryChanged.bind(this);
    this.onPaymentCityChanged = this.onPaymentCityChanged.bind(this);
    this.onPaymentAddressChanged = this.onPaymentAddressChanged.bind(this);
    this.onPaymentPhoneChanged = this.onPaymentPhoneChanged.bind(this);
    this.onPaymentEmailChanged = this.onPaymentEmailChanged.bind(this);
    this.onPaymentFirstNameChanged = this.onPaymentFirstNameChanged.bind(this);
    this.onPaymentLastNameChanged = this.onPaymentLastNameChanged.bind(this);
    if (props.user.session.is_guest) {
      props.dispatch({
        type: 'MESSAGES_ADD',
        payload: {
          id: 'make-payment',
          type: 'error',
          content: 'Please sign up or sign in to make a payment',
        },
      });

      // get booking ID from URL
      const PARSED = queryString.parse(window.location.search);
      const RETURN_URL = encodeURIComponent(`${PAGES.BOOKINGS_PAYMENT.PATH}?bookingId=${PARSED.bookingId}&tour=${PARSED.tour}`);
      navigate(`${PAGES.SIGN_UP.PATH}?return=${RETURN_URL}`);
      return null;
    }
  }

  componentDidUpdate(prevProps) {
    const { booking } = this.props;
    // if we have now got a booking
    if (!prevProps.booking.currentBooking && booking.currentBooking) {
      this.updatePaymentDetails();
    }
  }

  updatePaymentDetails() {
    const { booking } = this.props;
    const { currentBooking, travellers } = booking;
    this.setState({
      firstName: travellers[0].first_name,
      lastName: travellers[0].last_name,
      contactEmail: currentBooking.contact_email,
      phoneNumber: currentBooking.phone,
      address: '',
      city: '',
      country: '',
    });
  }

  onPaymentCountryChanged(e) {
    this.setState({ country: e.Country });
  }

  onPaymentCityChanged(e) {
    this.setState({ city: e.currentTarget.value });
  }

  onPaymentAddressChanged(e) {
    this.setState({ address: e.currentTarget.value });
  }

  onPaymentPhoneChanged(e) {
    this.setState({ phoneNumber: e.currentTarget.value });
  }

  onPaymentEmailChanged(e) {
    this.setState({ contactEmail: e.currentTarget.value });
  }

  onPaymentLastNameChanged(e) {
    this.setState({ lastName: e.currentTarget.value });
  }

  onPaymentFirstNameChanged(e) {
    this.setState({ firstName: e.currentTarget.value });
  }

  getPayments() {
    const { booking } = this.props;
    const {
      firstName,
      lastName,
      contactEmail,
      phoneNumber,
      address,
      city,
      country,
    } = this.state;
    return (
      <div className="l-booking-payments">
        {
          booking.currentBooking
            ? (
              <>
                {
                  booking.currentBooking.balance_remaining > 0
                    ? (
                      <>
                        <div className="l-two-col">
                          <div className="u-card">
                            <form>
                              <Input
                                id="FirstName"
                                name="FirstName"
                                label="First Name &#42;"
                                defaultValue={firstName}
                                placeholder="First Name"
                                onChange={this.onPaymentFirstNameChanged}
                                minLength={5}
                                required
                              />
                              <Input
                                id="LastName"
                                name="LastName"
                                label="Last Name &#42;"
                                defaultValue={lastName}
                                placeholder="Last Name"
                                onChange={this.onPaymentLastNameChanged}
                                minLength={5}
                                required
                              />
                              <Input
                                id="EmailAddress"
                                name="EmailAddress"
                                label="Email Address &#42;"
                                defaultValue={contactEmail}
                                placeholder="Email Address"
                                onChange={this.onPaymentEmailChanged}
                                minLength={5}
                                required
                              />
                              <Input
                                id="PhoneNumber"
                                name="PhoneNumber"
                                label="Phone Number &#42;"
                                defaultValue={phoneNumber}
                                placeholder="Phone Number"
                                onChange={this.onPaymentPhoneChanged}
                                minLength={5}
                                maxLength={32}
                                required
                              />
                              <Input
                                id="Address"
                                name="Address"
                                label="Address &#42;"
                                placeholder="We do not except P.O. Box addresses"
                                defaultValue={address}
                                onChange={this.onPaymentAddressChanged}
                                minLength={5}
                                required
                              />
                              <Input
                                id="City"
                                name="City"
                                label="City &#42;"
                                placeholder="City"
                                defaultValue={city}
                                onChange={this.onPaymentCityChanged}
                                minLength={5}
                                required
                              />
                              <Select
                                id="Country"
                                name="Country"
                                label="Country of Residence &#42;"
                                value={country}
                                onChange={this.onPaymentCountryChanged}
                                placeholder="Note: this will determine the currency you pay in"
                                options={Countries}
                                required
                              />
                            </form>
                          </div>
                        </div>
                        <div className="l-booking-payments__options">
                          {
                            booking.currentBooking.can_pay_partially
                              ? (
                                <button
                                  type="button"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    if (NEW_ADVENTURE_TOUR_SLUGS.includes(booking.currentBooking.tour_slug)) {
                                      this.handlePayment(NEW_ADVENTURE_DEPOSIT);
                                    } else {
                                      this.handlePayment(BOOKING_DEPOSIT);
                                    }
                                  }}
                                  className="c-button c-button--primary"
                                >
                                  Pay ${NEW_ADVENTURE_TOUR_SLUGS.includes(booking.currentBooking.tour_slug) ? NEW_ADVENTURE_DEPOSIT : BOOKING_DEPOSIT} Deposit
                                </button>
                              ) : ''
                          }
                          {console.log("Slug: ", booking.currentBooking)}
                          <button
                            type="button"
                            onClick={(e) => {
                              e.preventDefault();
                              this.handlePayment(booking.currentBooking.balance_remaining);
                            }}
                            className="c-button c-button--primary"
                          >
                            Pay Full Amount (${booking.currentBooking.balance_remaining} remaining)
                          </button>
                          {
                            booking.currentBooking.can_pay_partially
                              ? (
                                <form
                                  action=""
                                  className="l-booking-payments__partial-form"
                                  onSubmit={this.handlePartialSubmit}
                                >
                                  <button
                                    type="submit"
                                    className="c-button c-button--primary"
                                  >
                                    Pay Partial Amount
                                  </button>
                                  <FormInput
                                    id="PartialAmount"
                                    name="PartialAmount"
                                    type="number"
                                    placeholder={`Enter what you would like to pay ($${NEW_ADVENTURE_TOUR_SLUGS.includes(booking.currentBooking.tour_slug) ? NEW_ADVENTURE_DEPOSIT : BOOKING_DEPOSIT} minimum)`}
                                    required
                                    attrs={{
                                      min: NEW_ADVENTURE_TOUR_SLUGS.includes(booking.currentBooking.tour_slug) ? NEW_ADVENTURE_DEPOSIT : BOOKING_DEPOSIT,
                                    }}
                                  />
                                </form>
                              ) : ''
                          }
                        </div>
                      </>
                    ) : null
                }
                {booking.payments.length
                  ? (
                    <div className="l-booking-payments__history">
                      <h4 className="c-heading c-heading--h3 l-booking-payments__history-title">Payments History</h4>
                      <table>
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Time</th>
                            <th>You Paid ({CURRENCY}):</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                          booking.payments.map(payment => (
                            <tr key={payment.id}>
                              <td>
                                <h5 className="c-heading c-heading--h4">{moment(payment.created_at).format('D/MM/YY')}</h5>
                              </td>
                              <td>
                                <h5 className="c-heading c-heading--h4">{moment(payment.created_at).format('h:mma')}</h5>
                              </td>
                              <td>
                                <h5 className="c-heading c-heading--h4">${payment.amount}</h5>
                              </td>
                            </tr>
                          ))
                        }
                        </tbody>
                      </table>
                    </div>
                  )
                  : null
                }
              </>
            ) : <h4 className="c-heading c-heading--h4">Loading...</h4>
        }
      </div>
    );
  }

  /**
   * Handle partial payment form submit
   * @param e
   */
  handlePartialSubmit(e) {
    e.preventDefault();
    const FORM = e.currentTarget;
    const FORM_DATA = new FormData(FORM);
    if (FORM.checkValidity()) {
      this.handlePayment(FORM_DATA.get('PartialAmount'));
    }
  }

  handlePayment(paymentAmount, cardID) {
    const { user, booking, dispatch } = this.props;
    const {
      firstName,
      lastName,
      contactEmail,
      phoneNumber,
      address,
      city,
      country,
    } = this.state;
    const API_URL = process.env.GATSBY_API_URL;
    // const API_URL = "http://ce9a04b4.ngrok.io/api";
    const BASE_URL = process.env.GATSBY_BASE_URL;
    const FLYWIRE_CODE = process.env.GATSBY_FLYWIRE_CODE;
    const FLYWIRE_ENV = process.env.GATSBY_FLYWIRE_ENV;
    const number_payment = booking.payments.length + 1;

    if (firstName && lastName && contactEmail && phoneNumber && address && city && country) {
      var config = {
        env: FLYWIRE_ENV,
        recipientCode: FLYWIRE_CODE,
        locale: "en",
        amount: parseFloat(paymentAmount),
        firstName: firstName,
        lastName: lastName,
        address: address,
        city: city,
        country: country,
        email: contactEmail,
        phone: phoneNumber,
        recipientFields: {
          booking_reference: booking.currentBooking.reference_code,
        },

        // Set the return URL
        returnUrl: `${BASE_URL}/bookings/payments/success?count=${number_payment}&bookingId=${booking.currentBooking.id}`,

        onInvalidInput(errors) {
          dispatch({
              type: 'MESSAGES_ADD',
              payload: {
                  id: 'make-payment',
                  type: 'error',
                  content: errors.map(x => x.msg).join('; ')
              },
          });
          setTimeout(() => {
              dispatch({
                  type: 'MESSAGES_REMOVE',
                  payload: 'make-payment',
              });
          }, 5000);
        },

        // Enable payment status notification callbacks
        callbackUrl: `${API_URL}/payment_sessions/create_by_flywire`,
        // callbackUrl: "http://f48cbd22.ngrok.io/api/payment_sessions/create_by_flywire",
        callbackId: booking.currentBooking.reference_code,
      };

      window.FlywirePayment.initiate(config).render();
    } else {
      dispatch({
        type: 'MESSAGES_ADD',
        payload: {
          id: 'make-payment',
          type: 'error',
          content: 'Please fill in the required field',
        },
      });

      setTimeout(() => {
        dispatch({
          type: 'MESSAGES_REMOVE',
          payload: 'make-payment',
        });
      }, 5000);
    }
  }

  render() {
    const { user, booking } = this.props;
    const { currentBooking } = booking;
    return (
      <BookingLayout page="bookings/payments">
        <BookingHeader
          title="YOUR PAYMENT INFORMATION"
          subTitle="Partial payments or deposits are only available for departures greater than 60 days from now"
        />
        {
          user.session.is_guest
            ? <h4>You need to sign up or sign in before making a payment.</h4>
            : this.getPayments()
        }
      </BookingLayout>
    );
  }
}

BookingsPaymentsPage.propTypes = {
  user: PropTypes.object.isRequired,
  booking: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(BookingsPaymentsPage);
